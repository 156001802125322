'use client';

import { Classes } from '@blueprintjs/core';
import type { BaseStylesProps } from '@primer/react';
import { BaseStyles } from '@primer/react';
import cx from 'classnames';
import { useEffect } from 'react';

import { ColorModeCookieName, isColorModeDark } from '@/colormode';
import { defaultColorMode, useTheme } from '@/lib/primer/ThemeProvider';

export default function BaseStylesWithColorMode({ children, ...props }: BaseStylesProps) {
  const { colorMode, resolvedColorMode } = useTheme();
  const mode = resolvedColorMode ?? (colorMode != null && colorMode !== 'auto' ? colorMode : defaultColorMode);
  useEffect(() => {
    try {
      if (colorMode) {
        document.cookie = `${ColorModeCookieName}=${colorMode}; SameSite=Lax; Path=/; Secure`;
      }
    } catch (e) {
      console.error('Failed to set color mode cookie', e);
    }
  }, [colorMode]);
  return (
    <BaseStyles {...props} className={cx(props.className, isColorModeDark(mode) ? Classes.DARK : undefined)}>
      {children}
    </BaseStyles>
  );
}
