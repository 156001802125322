'use client';

import { MoonIcon, SunIcon } from '@primer/octicons-react';
import type { IconButtonProps } from '@primer/react';
import { IconButton } from '@primer/react';
import { useCallback } from 'react';

import { getColorModeInverse, isColorMode, isColorModeDark } from '@/colormode';
import { defaultColorMode, useTheme } from '@/lib/primer/ThemeProvider';

export default function ColorModeToggleButton(
  props: Omit<IconButtonProps, 'aria-label' | 'aria-labelled-by' | 'icon' | 'onClick' | 'value'>
) {
  const { colorMode, resolvedColorMode, setColorMode } = useTheme();
  const mode = resolvedColorMode ?? (colorMode != null && colorMode !== 'auto' ? colorMode : defaultColorMode);
  const toggle: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    e => {
      const mode = e.currentTarget.value;
      if (isColorMode(mode)) {
        setColorMode(getColorModeInverse(mode));
      }
    },
    [setColorMode]
  );
  return (
    <IconButton
      {...props}
      aria-labelledby=""
      icon={isColorModeDark(mode) ? SunIcon : MoonIcon}
      value={mode}
      onClick={toggle}
    />
  );
}
