import type { ColorMode, ColorModeWithAuto } from '@/lib/primer';

export const ColorModeCookieName = '__Secure-colormode';

export const isColorMode = (value?: unknown): value is ColorMode =>
  typeof value === 'string' && (['dark', 'light', 'day', 'night'] as const).includes(value);

export const parseColorMode = (value?: unknown): ColorModeWithAuto => (isColorMode(value) ? value : 'auto');

export const isColorModeDark = (mode: ColorMode) => mode === 'dark' || mode === 'night';

const InverseColorMode = {
  day: 'night',
  night: 'day',
  light: 'dark',
  dark: 'light',
} as const;

export const getColorModeInverse = (mode: ColorMode) => InverseColorMode[mode];
