import(/* webpackMode: "eager", webpackExports: ["Spinner","theme"] */ "__barrel_optimize__?names=Spinner,theme!=!/srv/app/.yarn/__virtual__/@primer-react-virtual-52b3b6ee41/0/cache/@primer-react-npm-37.6.0-f0d5c92450-9b31593c3b.zip/node_modules/@primer/react/lib-esm/index.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/@blueprintjs-core-virtual-61eb5f4db6/0/cache/@blueprintjs-core-npm-5.16.1-a830d0ee4f-6ba40d7a5f.zip/node_modules/@blueprintjs/core/lib/css/blueprint.css");
;
import(/* webpackMode: "eager", webpackExports: ["BlueprintProvider"] */ "/srv/app/.yarn/__virtual__/@blueprintjs-core-virtual-61eb5f4db6/0/cache/@blueprintjs-core-npm-5.16.1-a830d0ee4f-6ba40d7a5f.zip/node_modules/@blueprintjs/core/lib/esm/context/blueprintProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["HotkeysContext","HotkeysProvider"] */ "/srv/app/.yarn/__virtual__/@blueprintjs-core-virtual-61eb5f4db6/0/cache/@blueprintjs-core-npm-5.16.1-a830d0ee4f-6ba40d7a5f.zip/node_modules/@blueprintjs/core/lib/esm/context/hotkeys/hotkeysProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["OverlaysContext","OverlaysProvider"] */ "/srv/app/.yarn/__virtual__/@blueprintjs-core-virtual-61eb5f4db6/0/cache/@blueprintjs-core-npm-5.16.1-a830d0ee4f-6ba40d7a5f.zip/node_modules/@blueprintjs/core/lib/esm/context/overlays/overlaysProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["PortalContext","PortalProvider"] */ "/srv/app/.yarn/__virtual__/@blueprintjs-core-virtual-61eb5f4db6/0/cache/@blueprintjs-core-npm-5.16.1-a830d0ee4f-6ba40d7a5f.zip/node_modules/@blueprintjs/core/lib/esm/context/portal/portalProvider.js");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/@blueprintjs-icons-virtual-4b9fb0f0a6/0/cache/@blueprintjs-icons-npm-5.15.1-98f21e1856-56f00fb8eb.zip/node_modules/@blueprintjs/icons/lib/css/blueprint-icons.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/base/size/size.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/functional/size/border.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/functional/size/size-coarse.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/functional/size/size.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/functional/typography/typography.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/functional/themes/dark-dimmed.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/functional/themes/dark-tritanopia.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/functional/themes/light-colorblind.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/functional/themes/light-tritanopia.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/__virtual__/next-virtual-26365c4bec/0/cache/next-npm-15.0.4-686f370371-2dc86dcb31.zip/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/srv/app/packages/web-ui/src/app/BaseStylesWithColorMode.tsx");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/app/globals-post-blueprint.scss");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/base/typography/typography.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/functional/size/breakpoints.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/functional/size/size-fine.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/functional/size/viewport.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/functional/themes/dark-colorblind.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/functional/themes/dark-high-contrast.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/functional/themes/dark.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/functional/themes/light-high-contrast.css");
;
import(/* webpackMode: "eager" */ "/srv/app/.yarn/cache/@primer-primitives-npm-10.0.0-34b0090e23-9776fe62e9.zip/node_modules/@primer/primitives/dist/css/functional/themes/light.css");
;
import(/* webpackMode: "eager" */ "/srv/app/packages/web-ui/src/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/srv/app/packages/web-ui/src/auth/client.ts");
;
import(/* webpackMode: "eager", webpackExports: ["FidantAPIClientProvider"] */ "/srv/app/packages/web-ui/src/backend/client.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/srv/app/packages/web-ui/src/components/ColorModeToggleButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/srv/app/packages/web-ui/src/lib/primer/ThemeProvider.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/srv/app/packages/web-ui/src/lib/registry.tsx");
